import React, { useContext } from 'react';
import { FaTimes, FaCog } from 'react-icons/fa';
import { useTheme } from './ThemeContext'; // Import useTheme
import './SettingsPopup.css';

const SettingsPopup = ({ onClose }) => {
  const { theme, toggleTheme } = useTheme(); // Use the useTheme hook

  const handleThemeChange = (event) => {
    const selectedTheme = event.target.value;
    toggleTheme(selectedTheme); // Toggle theme based on selection
  };

  return (
    <div className="overlay">
      <div className="popup">
        <div className="popup-header">
          <h1><FaCog className="icon" />Settings</h1>
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="popup-content">
          <label htmlFor="theme-select">Select Theme:</label>
          <select id="theme-select" value={theme} onChange={handleThemeChange}>
            <option value="dark">Dark</option>
            <option value="light">Light</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default SettingsPopup;
