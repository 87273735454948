// src/StatisticsPopup.js
import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { auth } from './firebase';
import './StatisticsPopup.css';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTheme } from './ThemeContext'; // Import the theme context


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const StatisticsPopup = ({ onClose }) => {
  const { theme } = useTheme();
  const [stats, setStats] = useState({});
  
  const fetchStatistics = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const userId = auth.currentUser?.uid;

    if (userId) {
      try {
        const idToken = await auth.currentUser.getIdToken();
        const response = await axios.get(`${apiUrl}/statistics/${userId}`, {
          headers: { Authorization: `Bearer ${idToken}` }
        });
        setStats(response.data);
      } catch (error) {
        console.error('Failed to fetch statistics:', error);
      }
    } else {
      console.error('No user logged in');
    }
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  const defaultGuessDistribution = {
    '1': 0,
    '2': 0,
    '3': 0,
    '4': 0,
    '5': 0,
    '6': 0
  };

  const actualData = stats.guessDistribution || {};
  const combinedData = { ...defaultGuessDistribution, ...actualData };
  const filteredData = {
    '1': combinedData['1'],
    '2': combinedData['2'],
    '3': combinedData['3']
  };

  const transformData = (data) => {
    return data.map(value => (value === 0 ? 0.1 : value));
  };

  const data = {
    labels: Object.keys(filteredData),
    datasets: [
      {
        label: 'Guess Count',
        data: transformData(Object.values(filteredData)),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Guess Distribution',
        color: theme === 'dark' ? '#f2f2f2' : '#0f172a',
        font: {
          size: 24
        }
      },
      datalabels: {
        color: theme === 'dark' ? '#f2f2f2' : '#0f172a',
        anchor: 'end',
        align: 'start',
        font: {
          weight: 'bold'
        },
        formatter: (value) => (value === 0.1 ? 0 : value.toString()),
      }
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        ticks: {
          color: theme === 'dark' ? '#f2f2f2' : '#0f172a',
        },
        grid: {
          display: false,
          drawBorder: false
        }
      }
    }
  };

  return (
    <div className="overlay">
      <div className="popup">
        <div className="popup-header">
          <h1>Statistics</h1>
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="popup-content">
          <div className="stats-container">
            <div>
              <div className="statistic-number">{stats.totalGames}</div>
              <div className="statistic-label">Played</div>
            </div>
            <div>
              <div className="statistic-number">{stats.winPercentage}%</div>
              <div className="statistic-label">Win %</div>
            </div>
            <div>
              <div className="statistic-number">{stats.winStreak}</div>
              <div className="statistic-label">Current Streak</div>
            </div>
            <div>
              <div className="statistic-number">{stats.longestWinStreak}</div>
              <div className="statistic-label">Max Streak</div>
            </div>
          </div>
          <div className="chart-container">
            <Bar data={data} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsPopup;