import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobeProvider } from './GlobeContext';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { auth } from './firebase';
import axios from 'axios';
import { format } from 'date-fns';
import Header from './Header';
import GuessList from './GuessList';
import HowToPlayPopup from './HowToPlayPopup';
import AccountPopup from './AccountPopup';
import StatisticsPopup from './StatisticsPopup';
import SettingsPopup from './SettingsPopup';
import FAQ from './FAQ';
import { useTheme } from './ThemeContext';
import './App.css';

const Globe = lazy(() => import('./Globe'));

const App = () => {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sea, setSea] = useState(null);
  const [proximity, setProximity] = useState(null);
  const [direction, setDirection] = useState(null);
  const [guesses, setGuesses] = useState([]);
  const [gameComplete, setGameComplete] = useState(false);
  const [guessCount, setGuessCount] = useState(0);
  const [isWon, setisWon] = useState(false);
  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const [showAccountPopup, setShowAccountPopup] = useState(false);
  const [showStatisticsPopup, setShowStatisticsPopup] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);
  const [nextPuzzleTime, setNextPuzzleTime] = useState(null);

  const { theme } = useTheme();
  const apiUrl = process.env.REACT_APP_API_URL;

  const isPopupOpen = showHowToPlay || showAccountPopup || showStatisticsPopup || showSettingsPopup;

  const calculateTimeUntilNextPuzzle = () => {
    const now = new Date();
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    const timeUntil = tomorrow - now;
    return timeUntil;
  };

  const updateCountdown = () => {
    const timeUntil = calculateTimeUntilNextPuzzle();
    const hours = Math.floor(timeUntil / (1000 * 60 * 60));
    const minutes = Math.floor((timeUntil % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeUntil % (1000 * 60)) / 1000);
    setNextPuzzleTime(`${hours}h ${minutes}m ${seconds}s`);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idToken = await user.getIdToken();
        setToken(idToken);
      } else {
        signInAnonymously(auth)
          .then(async () => {
            const user = auth.currentUser;
            if (user) {
              const idToken = await user.getIdToken();
              setToken(idToken);
            }
          })
          .catch((error) => {
            console.error('Error signing in anonymously:', error);
            setLoading(false);
          });
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (token) {
      fetchSeaData(token);
      fetchGameState(token);
    }
  }, [token]);

  useEffect(() => {
    const isFirstTime = localStorage.getItem('firstTimeUser') === null;
    if (isFirstTime) {
      setShowHowToPlay(true);
      setIsFirstTimeUser(true);
      localStorage.setItem('firstTimeUser', 'no');
    }
  }, []);

  const fetchSeaData = (token) => {
    axios.get(`${apiUrl}/sea/${format(new Date(), 'yyyy-MM-dd')}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      console.log('Sea data:', response.data);
      setSea(response.data);
    })
    .catch(error => {
      console.error('Error fetching sea data:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
      }
    });
  };
  

  const fetchGameState = (token) => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      axios.get(`${apiUrl}/gameState/fetch/${userId}/${format(new Date(), 'yyyy-MM-dd')}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.data.gameState) {
          const { guesses, gameComplete, proximity, direction, win } = response.data.gameState;
          setGuesses(guesses);
          setGameComplete(gameComplete);
          setProximity(proximity);
          setDirection(direction);
          setGuessCount(guesses.length);
          setisWon(win);
        }
      })
      .catch(console.error);
    }
  };

  useEffect(() => {
    if (gameComplete) {
      if (isWon) {
        toast.success("Congratulations! You've won the game!");
        setGuesses((prevGuesses) => prevGuesses.filter(guess => !guess.isGhost));
      } else {
        toast.error("Game over! Better luck next time.");
      }
    }
  }, [gameComplete, isWon]);

  useEffect(() => {
    if (gameComplete) {
      updateCountdown();
      const timer = setInterval(updateCountdown, 1000);
      return () => clearInterval(timer);
    }
  }, [gameComplete]);

  const handleHowToPlayClose = () => {
    setShowHowToPlay(false);
    setIsFirstTimeUser(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <GlobeProvider>
      <Router>
        <div className={`app-container ${theme}`}>
          <Suspense fallback={<div>.</div>}>
            <div className="main-container">
              <Header 
                onAccountClick={() => setShowAccountPopup(true)} 
                onHowToPlayClick={() => setShowHowToPlay(true)} 
                onStatisticsClick={() => setShowStatisticsPopup(true)} 
                onSettingsClick={() => setShowSettingsPopup(true)} 
              />
              <Globe
                token={token}
                sea={sea}
                setSea={setSea}
                proximity={proximity}
                setProximity={setProximity}
                direction={direction}
                setDirection={setDirection}
                guesses={guesses}
                setGuesses={setGuesses}
                gameComplete={gameComplete}
                setGameComplete={setGameComplete}
                guessCount={guessCount}
                setGuessCount={setGuessCount}
                isWon={isWon}
                setisWon={setisWon}
                isPopupOpen={isPopupOpen}
              />
              <GuessList 
                sea={sea} 
                guesses={guesses} 
                gameComplete={gameComplete} 
                isWon={isWon}
                nextPuzzleTime={nextPuzzleTime}
              />
              <ToastContainer position="top-center" />
              {showHowToPlay && <HowToPlayPopup onClose={handleHowToPlayClose} isFirstTime={isFirstTimeUser} />}
              {showAccountPopup && <AccountPopup onClose={() => setShowAccountPopup(false)} />}
              {showStatisticsPopup && <StatisticsPopup onClose={() => setShowStatisticsPopup(false)} />}
              {showSettingsPopup && <SettingsPopup onClose={() => setShowSettingsPopup(false)} />}
              <div className="donation-link">
                <span style={{ fontSize: '20px' }}>❤️</span>
                <strong> Love SEALE? - </strong>
                <a href="https://buymeacoffee.com/seale" target="_blank" rel="noopener noreferrer">
                  Fund development!
                </a>
              </div>
              <div className="faq-container">
                <FAQ />
              </div>
            </div>
          </Suspense>
        </div>
      </Router>
    </GlobeProvider>
  );
};

export default App;
