// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyDlgU-496kPbq9-8YN7QL8g4Z0VayvPb7I",
  authDomain: "seale-65451.firebaseapp.com",
  databaseURL: "https://seale-65451-default-rtdb.firebaseio.com",
  projectId: "seale-65451",
  storageBucket: "seale-65451.appspot.com",
  messagingSenderId: "6313851056",
  appId: "1:6313851056:web:2c12347a515aee6868a375",
  measurementId: "G-NCQN9JRE52"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
