// src/AccountPopup.js
import React, { useState, useEffect } from 'react';
import { FaTimes, FaUser } from 'react-icons/fa';
import { auth } from './firebase';
import { signInWithPopup, GoogleAuthProvider, signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AccountPopup.css'; // Import the CSS file

const AccountPopup = ({ onClose }) => {
  const [user, setUser] = useState(null);
  const [loggedOut, setLoggedOut] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
        if (!loggedOut) {
          signInAnonymously(auth)
            .then((result) => {
              setUser(result.user);
            })
            .catch(console.error);
        }
      }
    });

    return () => unsubscribe();
  }, [loggedOut]);

  const handleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
        console.log(result.user);
      })
      .catch(console.error);
  };

  const handleLogout = () => {
    setLoggedOut(true);
    auth.signOut()
      .then(() => {
        console.log('User signed out');
        setUser(null);
        toast.success('Successfully logged out');
      })
      .catch(console.error);
  };

  return (
    <div className="overlay">
      <div className="popup">
        <div className="popup-header">
          <h1><FaUser className="icon" />Account</h1>
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="popup-content">
          {user && !user.isAnonymous ? (
            <div>
              <p>Welcome, {user.email || 'Google User'}!</p>
              <button className="button" onClick={handleLogout}>
                Logout
              </button>
            </div>
          ) : (
            <div>
              <p>Log in or Sign up to start saving your gaming statistics and playing across multiple devices.</p>
              <button className="button" onClick={handleLogin}>
                Sign in with Google
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountPopup;