import React from 'react';
import Faq from 'react-faq-component';
import styled from 'styled-components';

const FAQContainer = styled.div`
  width: 40%;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 85px;

  @media (max-width: 1100px) {
    width: 90%;
  }
`;

const data = {
  title: "F.A.Q.",
  rows: [
    {
      title: "When is a new game available?",
      content: "A new game is available every day at 0:00 am, local time of your device!",
    },
    
    {
      title: "Why is your data incorrect or old?",
      content: "Refresh your page to get the latest data. If the problem persists, please contact us...",
    },
    {
      title: "Where can I find previous Seale?",
      content: "Seale archive is currently a work in progress. Stay tuned for updates!",
    },
    {
      title: "What does the color of the progress bar mean?",
      content: "The color of the progress bar indicates the proximity of your guess to the correct answer. Green means you are close, red means you are far away!",
    },
    {
      title: "What is the proximity percent?",
      content: "The proximity percentage indicates how close your guess is to the correct answer. The higher the percentage, the closer you are!",
    },
    {
      title: "How are distances computed?",
      content: "Distances are computed by calculating the great-circle distance between two points on the Earth's surface. The distance is then converted to a percentage based on the maximum possible distance between two points on the Earth's surface.",
    },
    {
      title: "How are the seas selected?",
      content: "The seas are selected from a list based on what day it is. The list is updated daily at 0:00 am, local time of your device!",
    },
    {
      title: "Why do I see ads on my device?",
      content: "Ads are used to support the development and maintenance of this app. If you would like to remove ads, please consider purchasing the ad-free version of the game!",
    },
  ],
};

const styles = {
  bgColor: "var(--background-color)", // Background color to match the dark theme
  titleTextColor: "var(--text-color)", // White text for the title
  rowTitleColor: "var(--text-color)", // White text for the question titles
  rowContentColor: "var(--text-color)", // Light grey text for the content
  arrowColor: "var(--text-color)", // White color for the arrow
  rowContentPaddingLeft: "20px",
  rowContentPaddingRight: "20px",
  rowTitleTextSize: "16px",
  rowContentTextSize: "14px",
  titleTextSize: "22px",
};

const config = {
  animate: true,
  arrowIcon: "\u2228", // Unicode for logical OR symbol
  tabFocus: true
};

const FAQ = () => {
  return (
    <FAQContainer>
      <Faq data={data} styles={styles} config={config} />
    </FAQContainer>
  );
};

export default FAQ;