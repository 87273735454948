import React, { createContext, useContext, useRef } from 'react';

const GlobeContext = createContext();

export const useGlobeContext = () => useContext(GlobeContext);

export const GlobeProvider = ({ children }) => {
  const globeRef = useRef(null);
  const sceneRef = useRef(null);

  return (
    <GlobeContext.Provider value={{ globeRef, sceneRef }}>
      {children}
    </GlobeContext.Provider>
  );
};
