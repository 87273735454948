// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/SettingsPopup.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  position: relative;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.popup-header h1 {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 24px;
}

.icon {
  margin-right: 10px;
}

.close-button {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transform: translate(0, -1px);
}

.popup-content {
  width: 100%;
  text-align: left;
}

select {
  margin-top: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid var(--text-color);
  background-color: var(--background-color);
  color: var(--text-color);
}`, "",{"version":3,"sources":["webpack://./src/SettingsPopup.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,yCAAyC;EACzC,wBAAwB;EACxB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,wBAAwB;EACxB,eAAe;EACf,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,mCAAmC;EACnC,yCAAyC;EACzC,wBAAwB;AAC1B","sourcesContent":["/* src/SettingsPopup.css */\n.overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.8);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.popup {\n  position: relative;\n  background-color: var(--background-color);\n  color: var(--text-color);\n  padding: 20px;\n  border-radius: 8px;\n  width: 400px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.popup-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 20px;\n}\n\n.popup-header h1 {\n  display: flex;\n  align-items: center;\n  margin: 0;\n  font-size: 24px;\n}\n\n.icon {\n  margin-right: 10px;\n}\n\n.close-button {\n  background: none;\n  border: none;\n  color: var(--text-color);\n  font-size: 20px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  transform: translate(0, -1px);\n}\n\n.popup-content {\n  width: 100%;\n  text-align: left;\n}\n\nselect {\n  margin-top: 10px;\n  padding: 5px;\n  border-radius: 4px;\n  border: 1px solid var(--text-color);\n  background-color: var(--background-color);\n  color: var(--text-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
