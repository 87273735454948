// src/Header.js
import React from 'react';
import './Header.css';

const Header = ({ onAccountClick, onHowToPlayClick, onStatisticsClick, onSettingsClick }) => {
  return (
    <div className="header">
      <div className="left">
        <a href="#" onClick={onAccountClick}>🧞‍♂️</a>
        <a href="#" onClick={onHowToPlayClick}>❔</a>
      </div>
      <div className="title">
        <a href="#">🌊 Seale</a>
      </div>
      <div className="right">
        <a href="#" onClick={onStatisticsClick}>📊</a>
        <a href="#" onClick={onSettingsClick}>⚙️</a>
      </div>
    </div>
  );
};

export default Header;