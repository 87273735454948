// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --background-color: #0f172a;
  --text-color: #f2f2f2;
  --header-bg-color: transparent;
  --header-text-color: white;
  --header-link-color: white;
  --proximity-text-color: white;
}

.app-container.light {
  --background-color: #f2f2f2;
  --text-color: #0f172a;
}

.light {
  --header-bg-color: transparent;
  --header-text-color: #0f172a;
  --header-link-color: #0f172a;
  --proximity-text-color: #0f172a;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background-color: var(--background-color);
  color: var(--text-color);
}

.main-container {
  flex: 1 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--background-color);
  position: relative;
}

.donation-link {
  text-align: center;
  padding: 10px 0;
  color: var(--text-color);
}

.donation-link a {
  color: var(--text-color);
}

.faq-container {
  margin-top: 20px;
  padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,qBAAqB;EACrB,8BAA8B;EAC9B,0BAA0B;EAC1B,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,2BAA2B;EAC3B,qBAAqB;AACvB;;AAEA;EACE,8BAA8B;EAC9B,4BAA4B;EAC5B,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,cAAc;EACd,yCAAyC;EACzC,wBAAwB;AAC1B;;AAEA;EACE,SAAO;EACP,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[":root {\n  --background-color: #0f172a;\n  --text-color: #f2f2f2;\n  --header-bg-color: transparent;\n  --header-text-color: white;\n  --header-link-color: white;\n  --proximity-text-color: white;\n}\n\n.app-container.light {\n  --background-color: #f2f2f2;\n  --text-color: #0f172a;\n}\n\n.light {\n  --header-bg-color: transparent;\n  --header-text-color: #0f172a;\n  --header-link-color: #0f172a;\n  --proximity-text-color: #0f172a;\n}\n\n.app-container {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  overflow: auto;\n  background-color: var(--background-color);\n  color: var(--text-color);\n}\n\n.main-container {\n  flex: 1;\n  width: 100%;\n  height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n  background-color: var(--background-color);\n  position: relative;\n}\n\n.donation-link {\n  text-align: center;\n  padding: 10px 0;\n  color: var(--text-color);\n}\n\n.donation-link a {\n  color: var(--text-color);\n}\n\n.faq-container {\n  margin-top: 20px;\n  padding: 0 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
