// src/MapsButton.js
import React, { useState } from 'react';
import { FaMapMarkedAlt } from 'react-icons/fa';

const MapsButton = ({ seaName }) => {
  const [isHovered, setIsHovered] = useState(false);

  const openMaps = () => {
    const searchName = seaName.replace(/ /g, '+');
    const mapsUrl = `https://www.google.com/maps/place/${searchName}`;
    window.open(mapsUrl, '_blank');
  };

  return (
    <button
      style={{
        ...styles.button,
        backgroundColor: isHovered ? '#222e49' : '#0f172a',
      }}
      onClick={openMaps}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FaMapMarkedAlt style={styles.icon} />
      Google Maps
    </button>
  );
};

const styles = {
  button: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 10px',
    backgroundColor: '#0f172a',
    color: 'white',
    border: '1px solid white',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    marginRight: '10px', // Add margin to space out the buttons
  },
  icon: {
    marginRight: '8px',
  },
};

export default MapsButton;
