import React from 'react';

const ProgressInfo = ({ proximity, direction, isCorrect, isGhost, ghostNumber }) => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '300px',
    height: '20px', // Adjusted height
    color: 'var(--proximity-text-color)',
    border: '1px solid var(--proximity-text-color)',
    borderRadius: '5px',
    padding: '10px',
    marginBottom: '10px',
    position: 'relative',
    overflow: 'hidden'
  };

  const ghostStyle = {
    ...containerStyle,
    border: '1px dashed var(--proximity-text-color)',
    opacity: 0.5,
    justifyContent: 'center',
  };

  const getBackgroundColor = (proximity) => {
    if (proximity < 55) return '#ef4444'; // Red
    if (proximity < 70) return '#f97316'; // Orange
    if (proximity < 80) return '#eab308'; // Yellow
    if (proximity < 93) return '#84cc16'; // Light Green
    return '#16a34a'; // Green
  };

  const backgroundStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: `${proximity}%`,
    backgroundColor: getBackgroundColor(proximity),
    zIndex: 0,
    transition: 'width 0.5s ease'
  };

  const contentStyle = {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  };

  if (isGhost) {
    return (
      <div style={ghostStyle}>
        <em>{ghostNumber}</em>
      </div>
    );
  }

  if (isCorrect) {
    return (
      <div style={{ ...containerStyle, backgroundColor: '#16a34a', justifyContent: 'center' }}>
        <strong>Correct!</strong>
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      <div style={backgroundStyle}></div>
      <div style={contentStyle}>
        {proximity !== null && (
          <div>
            <strong>Proximity:</strong> {proximity}%
          </div>
        )}
        {direction !== null && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <i className="fas fa-arrow-right" style={{ transform: `rotate(${direction}deg)`, marginLeft: '10px' }}></i>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressInfo;